.verticalDivider {
  all: unset;
  display: flex;

  align-self: stretch;
  flex: 0 0 auto;
  border-left: 1px solid currentColor;
}

.horizontalDivider {
  all: unset;
  display: flex;

  align-self: stretch;
  flex: 0 0 auto;
  border-top: 1px solid currentColor;
}
