@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "kreate-common/modules/kreate-ui/styles/_fonts.scss" as fonts;
@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;
@use "sass:color" as color;

.toastViewport {
  --viewport-padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  margin: 0;
  max-width: 100vw;
  outline: none;
  padding: var(--viewport-padding);
  position: fixed;
  right: 0;
  top: 0;
  width: 390px;
  z-index: variables.$zIndexToast;
}

.toastRoot {
  background: colors.$backgroundLight;
  border-radius: 4px;
  box-shadow: 0px 60px 24px rgba(84, 84, 59, 0.01),
    0px 34px 20px rgba(84, 84, 59, 0.02), 0px 15px 15px rgba(84, 84, 59, 0.03),
    0px 4px 8px rgba(84, 84, 59, 0.04), 0px 0px 0px rgba(84, 84, 59, 0.04);
  color: colors.$ink;
  font-family: fonts.$fontSans;
  padding: 16px 24px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: relative;
  overflow-wrap: anywhere;

  &.error {
    background: #fceaea; // colors.$primary10
  }
}

.toastRoot[data-state="open"] {
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.toastRoot[data-state="closed"] {
  animation: hide 100ms ease-in;
}
.toastRoot[data-swipe="move"] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}
.toastRoot[data-swipe="cancel"] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}
.toastRoot[data-swipe="end"] {
  animation: swipeOut 100ms ease-out;
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}

.toastTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: colors.$ink;
  white-space: pre-wrap;
  padding-right: 24px;

  .colorInfo & {
    color: #2a5ea4;
  }

  .colorWarning & {
    color: #ec5929;
  }

  .colorSuccess & {
    color: #006e46;
  }

  .colorDanger & {
    color: #d02f2d;
  }

  .error & {
    color: colors.$primary;
  }
}

@mixin linkColor($color) {
  color: $color;

  &:hover,
  &:focus-visible {
    color: color.adjust($color, $lightness: -10%);
  }

  &:active {
    color: color.adjust($color, $lightness: -20%);
  }
}

.toastDescription {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: colors.$ink;
  white-space: pre-wrap;

  a {
    font-weight: 600;
    .colorInfo & {
      @include linkColor(#2a5ea4);
    }

    .colorWarning & {
      @include linkColor(#ec5929);
    }

    .colorSuccess & {
      @include linkColor(#006e46);
    }

    .colorDanger & {
      @include linkColor(#d02f2d);
    }
  }

  .error & {
    color: colors.$primary;
  }
}

.toastClose {
  position: absolute;
  right: 16px;
  top: 16px;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.button {
  all: unset;

  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  cursor: pointer;
  padding: 8px 16px;
  border: 1px solid rgba(34, 34, 34, 0.1);
  border-radius: 4px;

  &:hover,
  &:focus-visible {
    background: color.adjust(
      colors.$backgroundLight,
      $saturation: -50%,
      $lightness: -3%
    );
  }

  &:active {
    background: color.adjust(
      colors.$backgroundLight,
      $saturation: -70%,
      $lightness: -4%
    );
  }
}

.error {
  border: 1px solid colors.$primary;
  border-radius: 4px;
}
