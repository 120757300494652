@use "kreate-common/modules/kreate-ui/styles/_colors.scss" as colors;
@use "sass:color" as color;

// themeDefault must always appear first
.themeDefault,
.themeClassicLight {
  --on-primary--color-bg-main: #d02f2d;
  --on-primary--color-bg-hover: #{color.adjust(#d02f2d, $lightness: -8%)};
  --on-primary--color-bg-active: #{color.adjust(#d02f2d, $lightness: -16%)};
  --on-primary--color-fg-main: #ffffff;
  --on-primary--color-bg-disabled: #{color.adjust(#ffffff, $lightness: -32%)};
  --on-primary--color-fg-disabled: #{color.adjust(#ffffff, $lightness: -48%)};

  --on-secondary--color-bg-main: #ebebe0;
  --on-secondary--color-bg-hover: #c2c2b9;
  --on-secondary--color-bg-active: #85857e;
  --on-secondary--color-fg-main: #000000;
  --on-secondary--color-bg-disabled: #ebebe0;
  --on-secondary--color-fg-disabled: #{rgba(#000000, 48%)};

  --on-tertiary--color-bg-main: #000000;
  --on-tertiary--color-bg-hover: #{color.adjust(#000000, $lightness: +8%)};
  --on-tertiary--color-bg-active: #{color.adjust(#000000, $lightness: +16%)};
  --on-tertiary--color-fg-main: #ffffff;
  --on-tertiary--color-bg-disabled: #{color.adjust(#000000, $lightness: +32%)};
  --on-tertiary--color-fg-disabled: #ffffff;

  --on-navbar--color-bg-main: #{rgba(#ffffff, 8%)};
  --on-navbar--color-fg-main: #ffffff;
  --on-navbar--color-fg-border: #{rgba(#ffffff, 16%)};
  --on-navbar--color-bg-hover: #ffffff;
  --on-navbar--color-fg-hover: #000000;
  --on-navbar--color-bg-active: #d6d6d6;
  --on-navbar--color-fg-active: #000000;
  --on-navbar--color-bg-disabled: #{rgba(#ffffff, 8%)};
  --on-navbar--color-fg-disabled: #{rgba(#ffffff, 48%)};

  --color-bg-ground: #fffff9;
  --color-bg-main: #fffff3;
  --color-bg-surface: #f4f4e6;
  --color-bg-paper: #d5bc8f;
  --color-bg-backdrop: #333333;
  --color-bg-selected: #{rgba(#000000, 8%)};
  --color-bg-hover: #{rgba(#000000, 8%)};
  --color-bg-active: #{rgba(#000000, 16%)};
  --color-bg-navbar: #000000;
  --color-fg-main: #000000;
  --color-fg-dimmed: #{rgba(#000000, 64%)};
  --color-fg-disabled: #{rgba(#000000, 32%)};
  --color-fg-divider: #{rgba(#000000, 16%)};
  --color-fg-primary: #d02f2d;
  --color-fg-secondary: #000000;
  --color-ln-main: #d02f2d;
  --color-ln-hover: #{color.adjust(#d02f2d, $lightness: -8%)};
  --color-ln-active: #{color.adjust(#d02f2d, $lightness: -16%)};
  --color-ln-disabled: #{color.adjust(#d02f2d, $saturation: -100%)};

  --shadow: 0px 60px 24px rgba(23, 23, 20, 0.01),
    0px 34px 20px rgba(21, 21, 18, 0.02), 0px 15px 15px rgba(26, 26, 22, 0.03),
    0px 4px 8px rgba(20, 20, 16, 0.04), 0px 0px 0px rgba(32, 32, 27, 0.04);
}

.themeClassicDark {
  --on-primary--color-bg-main: #d64341;
  --on-primary--color-bg-hover: #{color.adjust(#d64341, $lightness: -8%)};
  --on-primary--color-bg-active: #{color.adjust(#d64341, $lightness: -16%)};
  --on-primary--color-fg-main: #ffffff;
  --on-primary--color-bg-disabled: #{color.adjust(#ffffff, $lightness: -32%)};
  --on-primary--color-fg-disabled: #{color.adjust(#ffffff, $lightness: -48%)};

  --on-secondary--color-bg-main: #373b44;
  --on-secondary--color-bg-hover: #{color.adjust(#373b44, $lightness: +8%)};
  --on-secondary--color-bg-active: #{color.adjust(#373b44, $lightness: +4%)};
  --on-secondary--color-fg-main: #ffffff;
  --on-secondary--color-bg-disabled: #373b44;
  --on-secondary--color-fg-disabled: #616161;

  --on-tertiary--color-bg-main: #ffffff;
  --on-tertiary--color-bg-hover: #{color.adjust(#ffffff, $lightness: -8%)};
  --on-tertiary--color-bg-active: #{color.adjust(#ffffff, $lightness: -16%)};
  --on-tertiary--color-fg-main: #000000;
  --on-tertiary--color-bg-disabled: #{color.adjust(#ffffff, $lightness: -32%)};
  --on-tertiary--color-fg-disabled: #000000;

  --on-navbar--color-bg-main: #2b2e36;
  --on-navbar--color-fg-main: #ffffff;
  --on-navbar--color-fg-border: transparent;
  --on-navbar--color-bg-hover: #ffffff;
  --on-navbar--color-fg-hover: #000000;
  --on-navbar--color-bg-active: #d6d6d6;
  --on-navbar--color-fg-active: #000000;
  --on-navbar--color-bg-disabled: #2b2e36;
  --on-navbar--color-fg-disabled: #{rgba(#ffffff, 48%)};

  --color-bg-ground: #191c24;
  --color-bg-main: #21252f;
  --color-bg-surface: #333640;
  --color-bg-paper: #191c24;
  --color-bg-backdrop: #1b1d20;
  --color-bg-selected: #{rgba(#ffffff, 8%)};
  --color-bg-hover: #{rgba(#ffffff, 8%)};
  --color-bg-active: #{rgba(#ffffff, 4%)};
  --color-bg-navbar: #191c24;
  --color-fg-main: #ffffff;
  --color-fg-dimmed: #{rgba(#ffffff, 64%)};
  --color-fg-disabled: #{rgba(#ffffff, 32%)};
  --color-fg-divider: #{rgba(#ffffff, 8%)};
  --color-fg-primary: #d64341;
  --color-fg-secondary: #ffffff;
  --color-ln-main: #d64341;
  --color-ln-hover: #{color.adjust(#d64341, $lightness: -8%)};
  --color-ln-active: #{color.adjust(#d64341, $lightness: -16%)};
  --color-ln-disabled: #{color.adjust(#d64341, $saturation: -100%)};

  --shadow: 0px 60px 24px rgba(23, 23, 20, 0.01),
    0px 34px 20px rgba(21, 21, 18, 0.02), 0px 15px 15px rgba(26, 26, 22, 0.03),
    0px 4px 8px rgba(20, 20, 16, 0.04), 0px 0px 0px rgba(32, 32, 27, 0.04);
}

.themeChocolate {
  --on-primary--color-bg-main: #d02f2d;
  --on-primary--color-bg-hover: #{color.adjust(#d02f2d, $lightness: -8%)};
  --on-primary--color-bg-active: #{color.adjust(#d02f2d, $lightness: -16%)};
  --on-primary--color-fg-main: #ffffff;
  --on-primary--color-bg-disabled: #{color.adjust(#ffffff, $lightness: -32%)};
  --on-primary--color-fg-disabled: #{rgba(#ffffff, 48%)};

  --on-secondary--color-bg-main: #cabeaf;
  --on-secondary--color-bg-hover: #{color.adjust(#cabeaf, $lightness: -4%)};
  --on-secondary--color-bg-active: #{color.adjust(#cabeaf, $lightness: -8%)};
  --on-secondary--color-fg-main: #000000;
  --on-secondary--color-bg-disabled: #cabeaf;
  --on-secondary--color-fg-disabled: #{rgba(#000000, 48%)};

  --on-tertiary--color-bg-main: #000000;
  --on-tertiary--color-bg-hover: #{color.adjust(#000000, $lightness: +8%)};
  --on-tertiary--color-bg-active: #{color.adjust(#000000, $lightness: +16%)};
  --on-tertiary--color-fg-main: #ffffff;
  --on-tertiary--color-bg-disabled: #{color.adjust(#000000, $lightness: +32%)};
  --on-tertiary--color-fg-disabled: #ffffff;

  --on-navbar--color-bg-main: #b48f75;
  --on-navbar--color-fg-main: #000000;
  --on-navbar--color-fg-border: transparent;
  --on-navbar--color-bg-hover: #{color.adjust(#b48f75, $lightness: -8%)};
  --on-navbar--color-fg-hover: #000000;
  --on-navbar--color-bg-active: #{color.adjust(#b48f75, $lightness: -16%)};
  --on-navbar--color-fg-active: #000000;
  --on-navbar--color-bg-disabled: #b48f75;
  --on-navbar--color-fg-disabled: #{rgba(#000000, 48%)};

  --color-bg-ground: #c49b7f;
  --color-bg-main: #dccfbe;
  --color-bg-surface: #cabeaf;
  --color-bg-paper: #c49b7f;
  --color-bg-backdrop: #1b1d20;
  --color-bg-selected: #{rgba(#000000, 8%)};
  --color-bg-hover: #{rgba(#000000, 8%)};
  --color-bg-active: #{rgba(#000000, 16%)};
  --color-bg-navbar: #c29a7d;
  --color-fg-main: #000000;
  --color-fg-dimmed: #{rgba(#000000, 64%)};
  --color-fg-disabled: #{rgba(#000000, 32%)};
  --color-fg-divider: #{rgba(#000000, 16%)};
  --color-fg-primary: #d02f2d;
  --color-fg-secondary: #ffffff;
  --color-ln-main: #d02f2d;
  --color-ln-hover: #{color.adjust(#d02f2d, $lightness: -8%)};
  --color-ln-active: #{color.adjust(#d02f2d, $lightness: -16%)};
  --color-ln-disabled: #{color.adjust(#d02f2d, $saturation: -100%)};

  --shadow: 0px 60px 24px rgba(23, 23, 20, 0.01),
    0px 34px 20px rgba(21, 21, 18, 0.02), 0px 15px 15px rgba(26, 26, 22, 0.03),
    0px 4px 8px rgba(20, 20, 16, 0.04), 0px 0px 0px rgba(32, 32, 27, 0.04);
}
