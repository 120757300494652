@use "kreate-common/modules/kreate-ui/styles/_variables.scss" as variables;

.body {
  --font-default: var(--font-family-inter);
  --font-sans: var(--font-family-inter);
  --font-serif: var(--font-family-playfair-display);

  font-family: var(--font-default);
  font-style: normal;
  // https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-numeric
  // We want to make digits (0-9) in Playfair Display looks less playful:
  font-variant-numeric: lining-nums;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: variables.$zIndexModal;
}
